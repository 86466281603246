@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  max-width: 3rem;
}

.App {
  /* background-color: #282c34; */
  background-color: #f9f9f9;
  color: #444444;
}

.Content {
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-container {
  background-image: url("./image/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-content {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
}

.button {
  /* border-radius: 0; */
  padding: 1rem 1.5rem;
  background-color: black;
  color: white;
}
.button:hover {
  background-color: white;
  color: black;
}

.about {
  border-radius: 60px 15px 60px 15px;
  box-shadow: 2px 2px 12px -2px rgb(100 100 100 / 50%);
}

.introduce {
  border-radius: 20px 20px 20px 20px;
  box-shadow: 2px 2px 12px -2px rgb(100 100 100 / 50%);
}

.why-section {
  /* border-top: 0.5px solid #444444; */
  border-bottom: 0.5px solid #444444;
}

.name {
  border-bottom: 1px solid #040404;
}

/* summary {
  display: flex;
  align-items: center;
} */
